body {
    background-image: url(https://media.timeout.com/images/105465271/1920/1080/image.jpg);
    background-color: rgba(255, 255, 255, 0.98);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    max-height: 100vh;
    width: 100dvw;
    /* From https://css.glass
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(5px); */
}

.text-area {
    background-color: #06050af5;
    color: white;
    width: 93%;
    border-radius: 5px;
    margin-left: 1.5%;
    margin-top: 2%;
    margin-bottom: 2%;
    padding: 2%;
    font-weight: 400;
}

.mobile-body {
    background-color: rgba(240, 248, 255, 0.973);
}

.mobile-title {
    font-family: 'Raleway', sans-serif;
    font-size: 25px;
    /* color: #7f87f5; */
    font-weight: 800;
    margin-left: 5%;
}

.mobile-header {
    margin-top: 0;
    padding-top: 0;
    height: 5vh;
    margin: 0;
    display: flex;
    /* justify-content: center; */
    align-items: center;
}

.mobile-content {
    height: 83vh;
    width: 96vw;
    position: relative;
    overflow-y: scroll;
    word-wrap: break-word;
    padding-left: 2vw;
    padding-right: 2vw;
    /* background-color: aqua; */
}

.mobile-header {
    position: relative;
    min-height: 7vh;
    text-align: center;
    background-color: aliceblue;
}

.mobile-nav {
    height: 7vh;
}