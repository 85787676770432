body {
    background-color: blanchedalmond;
}

.book-mobile {
    padding-left: 15px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 60%;
}