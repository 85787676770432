.button-panel {
    position: absolute;
    top: 0;
    right: 0;
    width: 35vw;
    height: 100vh;
    background-color: aqua;
}

.option {
    width: 90%;
}