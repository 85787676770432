.reset-password {
    background-color: white;
    width: 25vw;
    height: 60vh;
    margin-left: 35vw;
    margin-top: 13vh;
    padding: 3vw;
}

body {
    background-color:cornsilk;
}

h2 {
    color: black;
}