.main {
    background-color: white;
    height: calc(100vh - 55px);
    width: 100vw;
    display: grid;
    grid-template-columns: 1fr 8fr;
    grid-template-rows: 70px 12fr;
}

* {
    font-family: Calibri;
}

/* * {
    border: black 1px solid;
} */

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

div {
}

.head {
    background-color: green;
    color: white;
    max-height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.outline {
    padding: 10px;
    padding-left: 10px;
    overflow: scroll;
}

.b-left {
    border-left: 1px rgb(129, 129, 129) solid;
    padding-left: 1px;
}

h4 {
    color: white;
    font-size: 22px;
    margin-left: 5px;
    font-weight: 300;
    letter-spacing: 0.35px;
}

h5 {
    font-size: 20px;
}

.datagridcontainer {
    overflow-y: scroll;
    overflow-x: scroll;
}

.userbuttons {
    padding: 10px;
}

.adduserdiv {
    width: 100%;
    height: 100%;
    min-height: 10vh;
    display: flex;
    /* outline: black solid 0.5px; */
}

.noticeBoard {

}

textarea {
    text-align: left;
    width: 96%;
    min-height: 80%;
    padding-left: 2px;

}