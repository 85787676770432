body {
    background-color: none;
}

.forms {
    position: absolute;
    right: 0;
    width: 60vw;
    height: 100vh;
    overflow: scroll;
    background-color: rgba(74, 76, 96, 0.502);
    /* display: flex; */
    /* flex-direction: column; */
}

button {
    width: 30%;
    height: 60px;
    margin-top: 10px;
    border: none;
    cursor: pointer;
    transition: 0.5s;
    font-size: 20px;
    border-radius: 3px;
    font-weight: 600;
    background-color: #000000;
    color: aliceblue;
}

button:hover {
    background-color:beige;
    font-size: 22px;
    color: black;
}

h1 {
    color: white;
}

.main {
    background-color: green;
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 10fr;
}

.flex {
    display: flex;
    align-items: center;
    justify-content: center;
}

.border {
    border: 1px black dotted;
    overflow-x: scroll;
}

/* button {
    height: 50%;
    font-size: 16px;
    margin-left: 10px;
    background-color:cadetblue;

}

button:hover {
    font-size: 16px;
    background: none;
    background-color:cadetblue;

} */
.existing {
    overflow-x: scroll;
}

.green {
    overflow: hidden;
}

.mainArea {
    height: calc(100vh - 55px);
    width: 100vw;
    background-color: white;
    display: grid;
    grid-template-columns: 2fr 8fr;
    grid-template-rows: 70px 12fr;
}

.head {
    background-color: green;
    color: white;
    max-height: 100%;
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
}

.outline {
    padding: 10px;
    padding-left: 10px;
    overflow: scroll;
}

.b-left {
    border-left: 1px rgb(129, 129, 129) solid;
    padding-left: 1px;
}

h4 {
    color: white;
    font-size: 22px;
    margin-left: 5px;
    font-weight: 300;
    letter-spacing: 0.35px;
    text-align: center;
}

.h4fr {
    text-align: left;
    color: white;
    font-size: 22px;
    margin-left: 15px;
    font-weight: 300;
    letter-spacing: 0.35px;
}

h5 {
    font-size: 20px;
}

.datagridcontainer {
    overflow-y: scroll;
    overflow-x: scroll;
}

.userbuttons {
    padding: 10px;
}

.adduserdiv {
    width: 100%;
    height: 100%;
    min-height: 10vh;
    /* outline: black solid 0.5px; */
}

.CreateBooking {
    width: 100%;
    min-height: 100px;
    overflow: scroll;
}

.buttonsArea {

}
.notice {
    text-align: left;
    padding-left: 2px;
    overflow-y: scroll;
}

h3 {
    float: right;
}