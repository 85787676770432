p {
    background-color: #06050aea;
    color: aliceblue;
}

.miniheader {
    background-color: rgb(25, 26, 46);
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0px;
    padding-bottom: 5px;
}

.p {
    background-color: #06050af5;
    width: 80%;
    margin-left: 9%;
    margin-top: 2%;
    margin-bottom: 2%;
    padding-left: 2%;
    padding-top: 0.001%;
    padding-bottom: 0.001%;
    border-radius: 4px;
}

h2 {
    color: white;
}

.contain {
    max-height: 85vh;
    overflow-y: scroll;
}

li {
    color: aliceblue;
}

a {
    cursor: pointer;
}