.mobile-title {
    font-size: 1.4em;
    padding-top: 7px;
}

.mobile-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 10fr;
    height: 100dvh;
    width: 100dvw;
    overflow-y: scroll;
    background-image: none;
    background-color: rgb(228, 228, 228);
}

.topbar {
    display: flex;
    align-items: center;
    padding-bottom: 4vh;
    /* border-bottom: 2px solid black; */
}

.info-cards {
    display: grid;
    grid-template-columns: 1fr;
    padding-left: 5vw;
    padding-right: 5vw;
    gap: 5vw;
    padding-bottom: 0;
    height: max-content;
}

.event {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    height: max-content;
    gap: 5px;
}

.flex {
    display: flex;
    align-items: center;
    justify-content: baseline;
}

.flex-justify-center {
    display: flex;
    justify-content: center;
    margin-bottom: 0.1vh;
}

.event-title {
    font-size: 1em;
    font-weight: 600;
    overflow-x: scroll;
    padding-left: 10px;
    border: 4px;
    margin-bottom: 1vh;
}

.date-info {
    display: flex;
    justify-content: center;
    margin-left: -5px;
}

.other-info {
    display: flex;
    justify-content: center;
}

.other-bubble {
    font-size: 1.1em;
    text-align: center;
    font-weight: 500;
    color: white;
    border-radius: 10px;
    padding: 8px;
    margin-right: 5px;
    background-color: rgb(52, 57, 97);
    height: max-content;
    min-width: 40%;
}

.date-bubble {
    font-size: 1.2em;
    text-align: center;
    font-weight: 500;
    color: white;
    border-radius: 10px;
    padding: 8px;
    margin-right: 5px;
    background-color: rgb(52, 57, 97);
    height: max-content;
}

.date-to {
    font-size: 1.1em;
    text-align: center;
    font-weight: 400;

}

.edit-menu {
   display: flex;
   flex-direction: column;
   align-items: center;
   height: 90dvh;
   width: 87dvw;

}

.grid {
    display: grid;
}

.edit-menu-title {
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 10px;
}

.edit-menu-select {
    font-size: 1.2em;
    margin-bottom: 15px;
    margin-top: 15px;
    font-weight: 400;
}

.edit-menu-select-title {
    font-size: 1.3em;
}

.edit-menu-select-info {
    font-size: 1.3em;
    margin-bottom: 15px;
    margin-top: 15px;
    font-weight: 600;
    letter-spacing: 0.2px;
}

body {
    background-color: rgb(228, 228, 228);
}