.white {
    width: 100vw;
    height: 100vh;
    background-color: aliceblue;
}
.signin-main {
    width: 27vw;
    height: 100vh;
    margin-left: calc(36.5vw - 15px);
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-left: 30px;
    padding-right: 30px;
}

.content {
    width: 100%;
    padding: 10%;
    display: flex;
    justify-content: center;
    justify-items: center;
    flex-direction: column;
    margin-left: 0%;
    margin-top: 4vh;
    gap: 25px;
}

.links1 {
    display: grid;
    grid-template-columns: 1fr 2fr;
    color: blue;
}

.link1 {
    font-size: 3vh;
    cursor: pointer;
    width: 30vw;
}

.floatLeft {
    float: left;
}

.floatRight {
    float: right;
    text-align: right;
}

.title {
    font-size: 3.3vh;
    text-align: center;
    padding: 0;
    margin-left: -3%;
}

.site-name {
    text-align: center;
    width: 100%;
    margin-top: 15vh;
}

.h1 {
    font-family: 'Raleway', sans-serif;  
    font-size: 30px;
    color: #7f87f5;
    font-weight: 800;
    margin-left: -1%;
    text-align: center;
}

.logo-img {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    padding-right: 10px;
}