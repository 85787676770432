p {
    background-color: #06050aea;
    color: aliceblue;
}

p {
    background-color: #06050aea;
    color: aliceblue;
}


h2 {
    color: white;
}

.containContact {
    width: 50vw;
    position:absolute;
    left: calc(25vw - 60px);
    background-color: black;
    top: 30vh;
    padding: 60px;
    border-radius: 5px;
}