body {
    background-image: url(https://media.timeout.com/images/105465271/1920/1080/image.jpg);
    background-color: rgba(255, 255, 255, 0.98);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100vw;
}

.flex {
    display: flex;
}

.mainHomeButtons {
    position: absolute;
    top: 40vh;
    width: 90vw;
    margin-left: 5vw;
    height: 5vh;
    display: flex;
}

/* Header */

.header {
    background-color: rgb(0, 0, 0);
    height: 55px;
    display: flex;
    justify-content: left;
    align-items: center;
}

button {
    margin: 0;
}


.links {
    display: flex;
    margin-left: 30px;
    width: 90%;
}

h1 {
    font-family: 'Raleway', sans-serif;
    font-size: 30px;
    color: #7f87f5;
    font-weight: 800;
    margin-left: 0.5%;
    cursor: pointer;
}

h3 {
    color: aliceblue;
}

a {
    color: white;
    font-size: 1.2em;
    justify-content: center;
    align-items: center;
    margin-right: 2%;
    white-space: nowrap;
}

a:visited {
    color: white;
}

/* Buttons and main page */

button {
    width: 100%;
    margin-left: 2.5%;
    margin-right: 2.5%;
    margin-top: 0%;
    margin-bottom: auto;
    height: 60px;
    border: none;
    cursor: pointer;
    transition: 0.5s;
    font-size: 20px;
    border-radius: 3px;
    font-weight: 600;
    background-color: #000000;
    color: aliceblue;
}

.menu {
    margin-left: 4.95%;
    width: 90%;
}

button:hover {
    background-color:beige;
    font-size: 22px;
    color: black;
}

.logout {
    float: right;
    cursor: pointer;
    position: absolute;
    right: 10px;
    color: #7f87f5;;
    color: white;
    font-size: 1.2em;
    margin-right: 2%;
    white-space: nowrap;
    transition: 0.5s;
}

.logout:hover {
    color: red;
}