.p {
    background-color: #06050af5;
    width: 80%;
    margin-left: 9%;
    margin-top: 2%;
    margin-bottom: 2%;
    padding-left: 2%;
    padding-top: 0.001%;
    padding-bottom: 0.001%;
    border-radius: 4px;
}

p {
    background-color: #06050aea;
    color: aliceblue;
}


h3 {
    color: aliceblue;
}