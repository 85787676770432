body {
    background-image: url(https://media.timeout.com/images/105465271/1920/1080/image.jpg);
    background-color: rgba(255, 255, 255, 0.98);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100vw;
}

.form {
    position: absolute;
    width: 55%;
    height: 80%;
    left: calc(22.5% - 5px);
    top: calc(10% - 5px);
    background-color: aqua;
    padding: 10px;

    overflow: scroll;

    background: rgba(255, 255, 255, 0.7);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    backdrop-filter: blur(25px);
    -webkit-backdrop-filter: blur(10px);
}

h1 {
    text-align: center;
    letter-spacing: 0.6px;
    font-weight: 300;
    font-size: 40px;
}
h2 {
    text-align: center;
    letter-spacing: 0.6px;
    font-weight: 300;
    font-size: 32px;
}

::-webkit-scrollbar {
    width: 0px; /* For horizontal scroll */
    height: 0px; /* For vertical scroll */
}

.forms {
    scrollbar-width: none;
}