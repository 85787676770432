.mobile-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 10fr;
    height: 100dvh;
    width: 100dvw;
    overflow-y: scroll;
    background-image: none;
    background-color: rgb(228, 228, 228);
}

.topbar {
    display: flex;
    align-items: center;
    padding-bottom: 4vh;
    /* border-bottom: 2px solid black; */
}

.user-card {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    height: max-content;
    gap: 5px;
    padding: 12px;
}


.flex {
    display: flex;
    align-items: center;
    justify-content: baseline;
}

.user-title {
    font-size: 1em;
    font-weight: 600;
    overflow-x: scroll;
    padding-left: 10px;
    margin-left: 0px;
    border: 4px;
    margin-bottom: 0.5vh;
}

.edit-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 90dvh;
    width: 87dvw;
    gap: 15px;
 }
 
.edit-menu-title {
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 10px;
}

.edit-menu-select {
    font-size: 1.2em;
    margin-bottom: 15px;
    margin-top: 15px;
    font-weight: 400;
}

.edit-menu-select-title {
    font-size: 1.3em;
}

