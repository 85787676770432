.vmenu {
    margin-left: 3%;
    width: 90%;
}

.p {
    background-color: #06050af5;
    width: 80%;
    margin-left: 9%;
    margin-top: 2%;
    margin-bottom: 2%;
    padding-left: 2%;
    padding-top: 0.001%;
    padding-bottom: 0.001%;
    border-radius: 4px;
}

a {
    color: #7f87f5;;
    font-size: 1.2em;
    justify-content: center;
    align-items: center;
    margin-right: 2%;
    white-space: nowrap;
  }

h2 {
    color: white;
}