.mobile-signin {
    height: 100dvh;
    width: 100vw;
    background-color: aliceblue;
    padding: 0vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3vh;
}

.signin-heading {
    margin-top: 0vh;
    font-weight: 300;
    font-size: 50px;
}

.logo-img-mobile {
    margin-top: 3vh;
    width: 30vw;
    margin-bottom: 8vh;
}

.label-input-mobile {
    font-size: 20px;
    margin-top: 2vh;
    margin-bottom: 2vh;
}

.mobile-logout {
    position: absolute;
    right: 5vw;
    color: red;
    font-size: 22px;
}